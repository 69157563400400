.info_token,
.token_value,
.swap {
  background-color: #000;
  color: #fff;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.info_token-bloc {
  background-color: #0f2ce7;
}

.token_value-bloc,
.swap_bloc {
  background-color: #292c34;
  padding: 5px;
}

.whitepaper_bloc-black {
  background-color: #000;
  border-radius: 10px;
  padding: 15px;
}

.whitepaper_bloc-grey {
  background-color: #292c34;
  padding-top: 10px;
  padding-bottom: 10px;
}

.whitepaper a {
  background-color: #6c757d;
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.ora_title {
  letter-spacing: 0.2rem;
}
.change_swap {
  border: 4px solid #282c34;
  border-radius: 10px;
  cursor: pointer;
  background-color: #2f2f2f;
}
.change_swap:hover {
}

.swap_row {
  justify-content: space-between;
}
h1 h3 {
  letter-spacing: 0.2rem;
}
.swap_card {
  background-color: #282c34;
  color: white;
}

.head_swap_card {
  background-color: #0f2ce7;
}

.swap_input {
  border: 1px solid #404040;
  border-radius: 10px;
  background-color: #404040;
}

.currency_box {
  border: #2f2f2f solid 1px;
  background-color: #2f2f2f;
  border-radius: 10px;
}

.img_logo {
  width: 35px;
}

.input_currency {
  border: none;
  background-color: #2f2f2f;
  color: white;
  text-align: end;
  margin-right: 5px;
  border-radius: 10px;
}

.input_currency:focus {
  border: none;
  background-color: #404040;
  color: white;
}

.balance {
  color: gainsboro;
  font-size: smaller;
}

.hover_balance {
  cursor: pointer;
}

.ouranos_info {
  background-color: #181818;
  color: white;
}

@media screen and (max-width: 734px) {
  .logo-eth {
    margin-left: 10px !important;
  }
} ;
