.nft-container p {
  margin: 0px;
}

.nft,
.bloc-info_nft {
  background-color: #000;
  color: #fff;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  border-radius: 10px;
  transition: 0.2s;
}

.bloc-info_nft:hover {
  background-color: #292c34;
  cursor: not-allowed;
}

.bloc-nft {
  background-color: #292c34;
  padding-bottom: 20px;
  padding-top: 20px;
}

.bloc-reward {
  background-color: #292c34;
  padding-bottom: 60px;
  padding-top: 60px;
}

.nft-img {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  text-align: center;
  margin: auto;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .nft-img {
    margin-top: 20px;
  }
}
