@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap");

* {
  font-family: "Source Code Pro", monospace;
}

body {
  font-family: "Source Code Pro", monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 740px) {
  body {
    height: 100% !important;
    padding-bottom: 20px;
  }

  .col-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
    overflow-x: hidden;
  }

  .col-md-4 {
    padding-right: 5px !important;
    padding-left: 5px !important;
    overflow-x: hidden;
  }

  .col-un {
    padding-bottom: 40px;
  }

  .col-trois {
    padding-top: 40px;
  }

  .input_currency {
    margin-right: 10px !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
