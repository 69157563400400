@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.info_token,
.token_value,
.swap {
  background-color: #000;
  color: #fff;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.info_token-bloc {
  background-color: #0f2ce7;
}

.token_value-bloc,
.swap_bloc {
  background-color: #292c34;
  padding: 5px;
}

.whitepaper_bloc-black {
  background-color: #000;
  border-radius: 10px;
  padding: 15px;
}

.whitepaper_bloc-grey {
  background-color: #292c34;
  padding-top: 10px;
  padding-bottom: 10px;
}

.whitepaper a {
  background-color: #6c757d;
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.ora_title {
  letter-spacing: 0.2rem;
}
.change_swap {
  border: 4px solid #282c34;
  border-radius: 10px;
  cursor: pointer;
  background-color: #2f2f2f;
}
.change_swap:hover {
}

.swap_row {
  justify-content: space-between;
}
h1 h3 {
  letter-spacing: 0.2rem;
}
.swap_card {
  background-color: #282c34;
  color: white;
}

.head_swap_card {
  background-color: #0f2ce7;
}

.swap_input {
  border: 1px solid #404040;
  border-radius: 10px;
  background-color: #404040;
}

.currency_box {
  border: #2f2f2f solid 1px;
  background-color: #2f2f2f;
  border-radius: 10px;
}

.img_logo {
  width: 35px;
}

.input_currency {
  border: none;
  background-color: #2f2f2f;
  color: white;
  text-align: end;
  margin-right: 5px;
  border-radius: 10px;
}

.input_currency:focus {
  border: none;
  background-color: #404040;
  color: white;
}

.balance {
  color: gainsboro;
  font-size: smaller;
}

.hover_balance {
  cursor: pointer;
}

.ouranos_info {
  background-color: #181818;
  color: white;
}

@media screen and (max-width: 734px) {
  .logo-eth {
    margin-left: 10px !important;
  }
} ;

* {
  font-family: "Source Code Pro", monospace;
}

body {
  font-family: "Source Code Pro", monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 740px) {
  body {
    height: 100% !important;
    padding-bottom: 20px;
  }

  .col-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
    overflow-x: hidden;
  }

  .col-md-4 {
    padding-right: 5px !important;
    padding-left: 5px !important;
    overflow-x: hidden;
  }

  .col-un {
    padding-bottom: 40px;
  }

  .col-trois {
    padding-top: 40px;
  }

  .input_currency {
    margin-right: 10px !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.staking p {
  margin: 0px;
}

.movie,
.oraPerTicket {
  background-color: #000;
  color: #fff;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.bloc-movie {
  background-color: #292c34;
  padding-bottom: 20px;
  padding-top: 20px;
}

.btn-staking button {
  background-color: #000;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 10px;
  border: 0;
  color: #fff;
  transition: 0.2s;
}

.btn-staking button:hover {
  background-color: #292c34;
}

.btn-unstake:disabled {
  cursor: not-allowed;
}

.staking-img {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
}

.col-left p {
  text-align: right;
  margin-right: 5px;
  font-size: 15px;
}

.col-right p {
  text-align: left;
  margin-left: 5px;
  font-size: 15px;
}

.qr-activate {
  font-size: 12px;
}

.approve {
  transition: 0.2s;
}

.approve:hover {
  cursor: not-allowed;
  background-color: #292c34;
}

.nft-container p {
  margin: 0px;
}

.nft,
.bloc-info_nft {
  background-color: #000;
  color: #fff;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  border-radius: 10px;
  transition: 0.2s;
}

.bloc-info_nft:hover {
  background-color: #292c34;
  cursor: not-allowed;
}

.bloc-nft {
  background-color: #292c34;
  padding-bottom: 20px;
  padding-top: 20px;
}

.bloc-reward {
  background-color: #292c34;
  padding-bottom: 60px;
  padding-top: 60px;
}

.nft-img {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  text-align: center;
  margin: auto;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .nft-img {
    margin-top: 20px;
  }
}

