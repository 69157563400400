.staking p {
  margin: 0px;
}

.movie,
.oraPerTicket {
  background-color: #000;
  color: #fff;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.bloc-movie {
  background-color: #292c34;
  padding-bottom: 20px;
  padding-top: 20px;
}

.btn-staking button {
  background-color: #000;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 10px;
  border: 0;
  color: #fff;
  transition: 0.2s;
}

.btn-staking button:hover {
  background-color: #292c34;
}

.btn-unstake:disabled {
  cursor: not-allowed;
}

.staking-img {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
}

.col-left p {
  text-align: right;
  margin-right: 5px;
  font-size: 15px;
}

.col-right p {
  text-align: left;
  margin-left: 5px;
  font-size: 15px;
}

.qr-activate {
  font-size: 12px;
}

.approve {
  transition: 0.2s;
}

.approve:hover {
  cursor: not-allowed;
  background-color: #292c34;
}
